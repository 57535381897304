export const Districts = [
    {value: 'Thủ Đức'},
	{value: 'Bình Dương'},
    {value: 'Quận 1'},
	{value: 'Quận 2'},
	{value: 'Quận 3'},
	{value: 'Quận 4'},
	{value: 'Quận 5'},
	{value: 'Quận 6'},
	{value: 'Quận 7'},
	{value: 'Quận 8'},
	{value: 'Quận 9'},
	{value: 'Quận 10'},
	{value: 'Quận 11'},
	{value: 'Quận 12'},
	{value: 'Bình Tân'},
	{value: 'Bình Thạnh'},
	{value: 'Gò Vấp'},
	{value: 'Phú Nhuận'},
	{value: 'Tân Bình'},
	{value: 'Tân Phú'}, 
	{value: 'Bình Chánh'}, 
	{value: 'Cần Giờ'},
	{value: 'Củ Chi'}, 
	{value: 'Hóc Môn'},
	{value: 'Nhà Bè'},
	{value: 'Long An'},
	{value: 'Đồng Nai'}, 
	{value: 'Quận khác'} 
]